import axios from "axios";

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default {
    login(email, password) {
        return axios.post("/oauth/token", {
            "grant_type": "password",
            "username": email,
            "password": password
        });
    },
    getCurrentMember() {
        return axios.get("/umbraco/api/memberapi/GetCurrentMember");
    },
    getCompleteProfileMember(key) {
        return axios.get(`/umbraco/api/memberapi/GetCompleteProfileMember?key=${key}`);
    },
    completeProfileMember(member) {
        return axios.post(`/umbraco/api/memberapi/CompleteProfile`, member);
    },
    requestResetPassword(email) {
        return axios.post(`/umbraco/api/memberapi/RequestResetPassword?email=${email}`);
    },
    resetPassword(password, token) {
        return axios.post(`/umbraco/api/memberapi/ResetPassword?password=${password}&token=${token}`);
    },
    updateProfile(member) {
        return axios.post(`/umbraco/api/memberapi/UpdateProfile`, member);
    },
}