import Vuex from 'vuex'

const store = new Vuex.Store({
  state: {
    loading: true,
    isLoggedIn: false,
    authUser: {},
    showBackButton: false,
    hasMultipleProjects: false
  },
  mutations: {
    setIsLoggedIn(state, payload) {
      state.isLoggedIn = payload;
      state.loading = false;
    },
    setAuthUser(state, payload) {
      state.authUser = payload;
    },
    setShowBackButton(state, payload) {
      state.showBackButton = payload;
    },
    setHasMultipleProjects(state, payload) {
      state.hasMultipleProjects = payload;
    }
  }
});

export default store;