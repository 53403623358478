<template>
  <div>
    <div class="row shadow-sm" style="z-index: 1">
      <div class="mt-3 pb-2 mb-2 border-bottom border-primary">
        <strong>Händelse</strong>
      </div>

      <div v-for="log in logs" :key="log" class="py-2 border-bottom">
        <div class="d-flex">
          <div class="flex-grow-1">
            <div style="line-height: 15px; white-space: pre; font-size: 12px">
              {{ log.message }}
            </div>
            <small
              ><span class="text-muted">{{
                $filters.formatDate(log.date, "yyyy-MM-DD")
              }}</span>
              <span class="text-primary">&nbsp;&nbsp;|&nbsp;&nbsp;</span
              ><span class="text-muted">{{
                $filters.formatDate(log.date, "HH:mm:ss")
              }}</span></small
            >
          </div>
          <div class="align-self-center">
            <i class="fas fa-inbox-in text-success" v-if="log.type == 20"></i>
            <i class="fas fa-inbox-out" v-else></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <pre>{{ alarms }}</pre> -->
</template>

<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Systems from "../api/systems";

export default {
  setup() {
    const store = useStore();
    const route = useRoute();
    const systemId = route.params.systemId;
    const logs = ref([]);

    onMounted(() => {
      store.commit("setShowBackButton", true);
      Systems.getSmsLog(systemId)
        .then((res) => {
          logs.value = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    });

    return { logs };
  },
};
</script>

<style>
</style>