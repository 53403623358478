import axios from 'axios';
import router from '../router';
import store from '../store'

export default function setup() {
    axios.interceptors.request.use(function(config) {
        const token = localStorage.accessToken;
        if(token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    }, function(err) {
        return Promise.reject(err);
    });

    axios.interceptors.response.use(function (config) {
        return config;
    }, function (error) {
        if (401 === error.response.status) {
            store.commit("setIsLoggedIn", false);
            store.commit("setAuthUser", {});

            router.push("/login");            
        }
        return Promise.reject(error);
    });
}