<template>
<div>
  <div class="mt-5 mb-5 text-center">Välj Projekt</div>

  <div>
    <div v-for="project in projects" :key="project.id" class="project">
      <div>
        {{ project.name }}
      </div>
      <div>
        <i class="fal fa-chevron-right"></i>
      </div>

      <router-link v-bind:to="'/projects/' + project.id"></router-link>
    </div>
  </div>
</div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import Projects from "../api/projects";
import router from "../router";
export default {
  setup() {
    const store = useStore();
    const projects = ref([]);

    function logout() {
      localStorage.removeItem("accessToken");
      store.commit("setIsLoggedIn", false);
      store.commit("setAuthUser", {});
      // window.location.reload();
    }

    onMounted(() => {
      Projects.getAll().then((res) => {
        projects.value = res.data;

        store.commit("setHasMultipleProjects", projects.value.length > 1);

        if(projects.value.length == 1){
          router.push("/projects/" + projects.value[0].id);
        }
      });
    });

    return { logout, projects };
  },
};
</script>

<style>
</style>