<template>
  <div class="container border pt-4 main">
    <Header v-if="isLoggedIn" />

    <router-view></router-view>
    <!-- <router-view v-slot="{ Component }">
      <transition name="slide" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view> -->

    <Footer />
  </div>

  <Menu v-show="isLoggedIn" />
</template>

<script>
import { onMounted, computed, watch } from "vue";
import Members from "./api/members";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Menu from "./components/Menu";
import { useStore } from "vuex";
import router from "./router";
import "bootstrap";

export default {
  components: { Footer, Header, Menu },
  setup() {
    const store = useStore();

    onMounted(() => {
      let urlParams = new URLSearchParams(window.location.search);
      if(urlParams.has('qlt')) {
          let token = urlParams.get('qlt');
          localStorage.accessToken = token;
      }    

      if (!localStorage.accessToken)  {     
        //router.push("/login");        
      } else {
        Members.getCurrentMember()
          .then((res) => {
            if (res.data) {
              store.commit("setIsLoggedIn", true);
              store.commit("setAuthUser", res.data);
            } else {
              store.commit("setIsLoggedIn", false);
              store.commit("setAuthUser", {});
            }
          })
          .catch(() => {
            store.commit("setIsLoggedIn", false);
            store.commit("setAuthUser", {});

            console.log("test");
          });
      }
    });

    const isLoggedIn = computed(() => {
      return store.state.isLoggedIn;
    });

    watch(isLoggedIn, (loggedIn) => {
      if (!loggedIn) {
        router.push("/login");
      }
    });

    const loading = computed(() => {
      return store.state.loading;
    });

    return { loading, isLoggedIn };
  },
};
</script>