import axios from "axios";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default {
    getAll() {
        return axios.get("/umbraco/api/projectapi/GetAll");
    },
    getById(id) {
        return axios.get(`/umbraco/api/projectapi/getbyid?id=${id}`);
    },
    getByIdLive(id) {
        return axios.get(`/umbraco/api/projectapi/GetByIdLive?id=${id}`);
    }
}