import axios from "axios";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

export default {
    getById(id) {
        return axios.get(`/umbraco/api/systemapi/getbyid?id=${id}`);
    },
    sync(id) {
        return axios.get(`/umbraco/api/systemapi/Sync?id=${id}`);
    },
    turnOn(id) {
        return axios.post(`/umbraco/api/systemapi/turnon?id=${id}`);
    },
    turnOff(id) {
        return axios.post(`/umbraco/api/systemapi/turnoff?id=${id}`);
    },
    reset(id) {
        return axios.post(`/umbraco/api/systemapi/reset?id=${id}`);
    },
    changePressure(id, pressure) {
        return axios.post(`/umbraco/api/systemapi/changePressure?id=${id}&pressure=${pressure}`);
    },
    changeLevel(id, level) {
        return axios.post(`/umbraco/api/systemapi/changeLevel?id=${id}&level=${level}`);
    },
    changeSpeed(id, speed) {
        return axios.post(`/umbraco/api/systemapi/changeSpeed?id=${id}&speed=${speed}`);
    },
    toggleSubPump(id, pumpNumber) {
        return axios.post(`/umbraco/api/systemapi/toggleSubPump?id=${id}&pumpNumber=${pumpNumber}`);
    },
    getAlarms(id) {
        return axios.get(`/umbraco/api/systemapi/getAlarms?id=${id}`);
    },
    getSmsLog(id) {
        return axios.get(`/umbraco/api/systemapi/getSmsLog?id=${id}`);
    }
}