<template>
  <LoadingModal :show="runningCommand" />
  <SystemInfo :system="system" />

  <div
    class="row mt-5 bg-light pb-4 text-center flex-grow-1"
    v-if="system && system.name"
  >
    <div>
      <div class="btn-group mt-n4">
        <button
          class="btn btn-primary"
          @click="command = 'on'; openModal()"
          v-bind:disabled="runningCommand"
          v-if="!system.readOnly"
        >
          On
        </button>

        <button
          class="btn btn-primary px-4"
          @click="sync"
          v-bind:disabled="runningCommand"
        >
          <i class="far fa-sync" />
        </button>

        <button
          class="btn btn-primary"
          @click="command = 'off'; openModal()"
          v-bind:disabled="runningCommand"
          v-if="!system.readOnly"
        >
          Off
        </button>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <div class="box">
            <div class="box__value">
              <div class="box__value__title">Hastighet</div>
              <div class="box__value__val">{{ $filters.number(system.speed , 1, " ", system.updateDate) }} m/h</div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="box">
            <div class="box__value">
              <div class="box__value__title">Längd</div>
              <div class="box__value__val">{{ $filters.number(system.extendedLength, 1, " ", system.updateDate) }} m</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="system.lastReceivedMessage">
        <div class="col-12">
          <div class="box">
            <div class="box__value">
              <div class="box__value__title">Senaste meddelandet</div>
              <div
                class="box__value__val mt-2"
                style="white-space: pre; font-size: 12px"
              >
                {{ system.lastReceivedMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CommandModal>
    <template #body>
      <div v-if="command == 'on'">Vill du starta Bevattningsmaskinen?</div>
      <div v-if="command == 'off'">Vill du stoppa Bevattningsmaskinen?</div>
    </template>
    <template #action>
      <button
        type="button"
        class="btn btn-primary btn-sm btn-rounded"
        v-if="command == 'on'"
        @click="turnOn"
      >
        Starta
      </button>
      <button
        type="button"
        class="btn btn-primary btn-sm btn-rounded"
        v-if="command == 'off'"
        @click="turnOff"
      >
        Stoppa
      </button>
    </template>
  </CommandModal>
  <teleport to="#headerAction" v-if="system && system.name">
    <router-link
      v-bind:to="'/projects/' + system.project.id + '/' + system.id + '/smslog'"
      class="text-dark"
    >
      <i class="fas fa-info-circle fa-lg text-dark"></i>
    </router-link>
  </teleport>
</template>

<script>
import { ref, onMounted, onUnmounted, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import Systems from "../../api/systems";
import SystemInfo from "./Shared/SystemInfo";
import LoadingModal from "../LoadingModal";
import CommandModal from "../CommandModal";
import { Modal } from "bootstrap";

export default {
  components: { SystemInfo, LoadingModal, CommandModal },
  props: ["currentSystem"],
  setup(props) {
    const system = ref(props.currentSystem);
    const runningCommand = ref(false);
    const route = useRoute();
    const systemId = route.params.systemId;
    let interval = "";
    const updateFreq = 10000;
    const { proxy } = getCurrentInstance();
    const $toast = proxy.$toast;
    const modal = ref({});
    const command = ref();

    function sync() {
      runningCommand.value = true;
      Systems.sync(systemId)
        .then((res) => {
          $toast.success("Inläsning påbörjad");
          runningCommand.value = false;
          system.value = res.data;
        })
        .catch((error) => {
          $toast.error("Kommando misslyckades:<br>" + error.response.data);
          runningCommand.value = false;
        });
    }

    function turnOn() {
      runningCommand.value = true;
      Systems.turnOn(systemId)
        .then(() => {
          $toast.success("Maskin startad");
          runningCommand.value = false;
          modal.value.hide();
        })
        .catch((error) => {
          $toast.error("Kommando misslyckades:<br>" + error.response.data);
          runningCommand.value = false;
        });
    }

    function turnOff() {
      runningCommand.value = true;
      Systems.turnOff(systemId)
        .then(() => {
          $toast.success("Maskin avstängd");
          runningCommand.value = false;
          modal.value.hide();
        })
        .catch((error) => {
          $toast.error("Kommando misslyckades:<br>" + error.response.data);
          runningCommand.value = false;
        });
    }

    function openModal() {
      modal.value.show();
    }

    onMounted(() => {
      modal.value = new Modal(document.getElementById("commandModal"));
      interval = setInterval(() => {
        Systems.getById(systemId).then((res) => {
          system.value = res.data;
        });
      }, updateFreq);
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    return {
      system,
      runningCommand,
      sync,
      turnOff,
      turnOn,
      command,
      openModal
    };
  },
};
</script>
<style>
</style>