import { createApp } from 'vue'
import App from './App.vue'
import '../src/scss/app.scss'
import router from "./router";
import store from "./store/index";
import interceptorsSetup from './common/interceptor';
import VueNumerals from 'vue-numerals';
import Toaster from '@meforma/vue-toaster';
import moment from "moment";
import "moment/locale/sv"; // without this line it didn't work
import './registerServiceWorker'
moment.locale("sv");

const app = createApp(App);
app.use(router);
app.use(store);
app.use(Toaster, {
  position: 'bottom',
});
app.use(VueNumerals, {
  locale: 'no' // se does not exist
});

app.config.globalProperties.$filters = {
  formatDate(date, format) {
    if (format)
      return moment(date).format(format);

    var d = moment(date);
    var iscurrentDate = d.isSame(new Date(), "day");
    if (iscurrentDate) {
      return "Idag " + moment(date).format("HH:mm:ss");
    }
    //return moment(date).format("HH:mm:ss D MMM");
    return d.format("D MMM HH:mm:ss");
  },
  number(value, decimals, fallback, date) {
    if (isNaN(parseFloat(value)) || date && (moment() > moment(date).add(20, 'minutes'))) {
      return fallback ? fallback : "?";
    }

    return parseFloat(value).toLocaleString("sv-SE", { maximumFractionDigits: decimals, minimumFractionDigits: decimals });
  }
}

app.mount("#app")

interceptorsSetup();

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);

// We listen to the resize event
window.addEventListener('resize', () => {
  // We execute the same script as before
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
});

// // Add a request interceptor
// axios.interceptors.request.use(function (config) {
//     // Do something before request is sent
//     // Check your token for validity, and if needed, refresh the token / force re-login etc.
//     console.log("sending request");
//     return config;
// }, function (error) {
//     console.log("error in request", error);
//     // Do something with request error
//     return Promise.reject(error);
// });