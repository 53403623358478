<template>
  <div class="offcanvas offcanvas-start" tabindex="-1" id="menu">
    <div class="offcanvas-header">
      <button
        type="button"
        class="btn-close text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        id="close-menu"
      ></button>
    </div>
    <div class="offcanvas-body">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a
            href="https://www.ostorpsbevattning.se/smartnet/"
            class="nav-link text-dark"
            target="_blank"
            >Information</a
          >
        </li>
        <li class="nav-item">
          <a
            href="https://www.ostorpsbevattning.se/smartnet/support-smartnet/"
            class="nav-link text-dark"
            target="_blank"
            >Hjälp</a
          >
        </li>
        <li class="nav-item">
          <a
            href="https://www.netbiter.net/"
            class="nav-link text-dark"
            target="_blank"
            >Inloggning till gamla systemet</a
          >
        </li>

        <li>
          <a
            class="nav-link text-dark"
            aria-current="page"
            href="#"
            @click="goTo('/profile')"
            >Byt lösenord</a
          >
        </li>
      </ul>
      <hr />
      <ul class="nav flex-column">
        <li class="nav-item">
          <a
            class="nav-link text-dark text-muted"
            aria-current="page"
            href="#"
            @click="signOut"
            >Logga ut</a
          >
        </li>
        <li class="nav-item" v-if="deferredPrompt">
          <a
            class="nav-link text-dark text-muted"
            aria-current="page"
            href="#"
            @click="install"
            >Installera SmartNet</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import router from "../router";
export default {
  setup() {
    const store = useStore();
    const deferredPrompt = ref();

    function signOut() {
      localStorage.removeItem("accessToken");
      store.commit("setIsLoggedIn", false);
      store.commit("setAuthUser", {});

      document.getElementById("close-menu").click();

      router.push("/login");
      // var bsOffcanvas = new bootstrap.Offcanvas(myOffcanvas)
      //bsOffcanvas.close();
    }

    function goTo(path) {
      document.getElementById("close-menu").click();
      router.push(path);
    }

   onMounted(() => {     
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        deferredPrompt.value = e;
      });
      window.addEventListener("appinstalled", () => {
        deferredPrompt.value = null;
      });
    });

    function install() {
      deferredPrompt.value.prompt();
    }

    return { signOut, goTo, install, deferredPrompt };
  }
};
</script>

<style>
</style>