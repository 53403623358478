<template>
  <form @submit.prevent="login">
    <div class="mt-3 mb-4">
      <div class="row justify-content-center">
        <div class="col-11">
          <div class="d-grid">
            <div class="mb-3">
              <label class="form-label"
                >Nuvarande lösenord
                <input
                  v-model="form.currentPassword"
                  type="password"
                  class="form-control shadow"
                  required
                />
              </label>
            </div>
            <div class="mb-3">
              <label class="form-label"
                >Lösenord
                <input
                  v-model="form.password"
                  type="password"
                  class="form-control shadow"
                  required
                />
              </label>
              <div class="form-text">
                Lösenordet måste innehålla minst 6 tecken.
              </div>
            </div>
            <div class="mb-3">
              <label class="form-label"
                >Bekräfta lösenord
                <input
                  v-model="form.confirmPassword"
                  type="password"
                  class="form-control shadow"
                  required
                />
              </label>
            </div>
            <button
              type="submit"
              class="btn btn-primary shadow"
              :disabled="form.submitting"
            >
              Uppdatera
            </button>

            <div class="mt-4 text-center">
              <router-link v-bind:to="'/projects/'"> Avbryt </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { getCurrentInstance, reactive } from "vue";
import Members from "../api/members";
import Error from "../common/errorResponse";
import router from "../router";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const $toast = proxy.$toast;

    const form = new reactive({
      currentPassword: "",
      password: "",
      confirmPassword: "",
      submitting: false,
    });

    function login() {
      form.submitting = true;
      Members.updateProfile(form)
        .then(() => {
          $toast.success("Din användare är nu uppdaterad");
          router.push("/projects");
        })
        .catch((error) => {
          form.submitting = false;
          $toast.error(new Error(error).getMessage());
        });
    }

    return { form, login };
  },
};
</script>

<style>
</style>