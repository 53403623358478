<template>
  <div class="mt-5">
    <div class="system" v-for="system in project.systems" :key="system.id">
      <!-- <div class="system__refresh" v-if="system.type == 20">
        <button
          class="btn shadow-sm btn-primary round"
          @click="sync(system)"
          v-bind:disabled="system.loading"
        >
          <i class="far fa-sync" />
        </button>
      </div> -->
      <div class="system__top">
        <div class="system__top__state system-state">
          <img
            :src="
              require(`@/assets/images/system/pump/pump-${system.state}.svg`)
            "
            v-if="system.type == 5"
          />
          <img
            :src="require(`@/assets/images/system/machine-${system.state}.svg`)"
            v-if="system.type == 20"
          />

          <div class="system-state__secondary-state" v-if="system.hand">
            <img src="../assets/images/icons/hand.svg" />
          </div>
        </div>
        <div class="system__top__info">
          <div class="system__top__info__small">
            <small
              ><span class="text-muted">{{ project.name }}</span>
              <span class="text-primary">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <strong class="text-uppercase">{{
                $filters.formatDate(system.updateDate)
              }}</strong></small
            >
          </div>
          <div class="system__top__info__name">
            {{ system.name }}
          </div>
        </div>
      </div>

      <div class="system__bottom" v-if="system.state != 40 && system.state != 50">
        <ul class="list-inline mb-0">
          <li
            class="list-inline-item"
            v-for="property in system.properties"
            :key="property"
          >
            <i
              class="fal fa-tachometer-average"
              v-if="property.unit == 'Bar'"
            ></i>
            <i class="fal fa-bolt" v-if="property.unit == 'kW'"></i>
            <i class="fal fa-heart-rate" v-if="property.unit == 'Hz'"></i>
            <i class="fal fa-tachometer-alt" v-if="property.unit == 'm/h'"></i>
            <i class="fal fa-ruler-horizontal" v-if="property.unit == 'm'"></i>
            <i class="fal fa-ruler-vertical" v-if="property.unit == 'cm'"></i>
            <i
              class="fal fa-dewpoint"
              v-if="property.unit == 'm³/h' || property.unit == 'l/m'"
            ></i>
            <small
              >{{ $filters.number(property.value, 1, " ", system.updateDate) }}
              {{ property.unit }}</small
            >
          </li>
        </ul>
      </div>

      <router-link
        v-bind:to="'/projects/' + projectId + '/' + system.id"
      ></router-link>
    </div>
  </div>

  <!-- <pre>{{ project }}</pre> -->
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import Projects from "../api/projects";
import Systems from "../api/systems";

export default {
  setup(_, { emit }) {
    const route = useRoute();
    const projectId = route.params.id;
    const project = ref({});
    let interval = "";
    const store = useStore();

    function sync(system) {
      system.loading = true;
      Systems.sync(system.id).then((res) => {
        console.log(res);
        system.loading = false;
      });
    }

    onMounted(() => {
      Projects.getById(projectId)
        .then((res) => {
          project.value = res.data;
        })
        .catch((error) => {
          console.log(error);
        });

      interval = setInterval(() => {
        Projects.getByIdLive(projectId)
          .then((res) => {
            project.value = res.data;
          })
          .catch((error) => {
            console.log(error);
          });
      }, 20000);

      store.commit("setShowBackButton", store.state.hasMultipleProjects);
      emit("show-back-button");
    });

    onUnmounted(() => {
      clearInterval(interval);
    });

    return { projectId, project, sync };
  },
};
</script>

<style>
</style>