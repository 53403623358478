<template>
  <LoadingModal :show="submitting" />
  <div class="row justify-content-center">
    <div class="col-10">
      <div class="row justify-content-center">
        <div class="col-9">
          <div class="text-center my-5">
            <img src="../assets/images/logo.svg" alt="Logo" />
          </div>
        </div>
      </div>

      <form @submit.prevent="requestResetPassword" v-if="!token">
        <div class="mt-3 mb-4">
          <div class="mb-3">
            <div class="text-center">
              <p>Fyll i din e-postadress och följ instruktionerna i mailet.</p>
            </div>

            <input
              v-model="form.email"
              type="text"
              class="form-control shadow"
              placeholder="E-postadress"
              required
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-11">
            <div class="d-grid">
              <button
                type="submit"
                class="btn btn-primary shadow"
                :disabled="submitting"
              >
                Skicka
              </button>
            </div>
          </div>
        </div>
        <div class="mt-4 text-center">
          <router-link v-bind:to="'/login/'"> Tillbaka </router-link>
        </div>
      </form>
      <form @submit.prevent="resetPassword" v-else>
        <div class="mt-3 mb-4">
          <div class="mb-3">
            <div class="text-center">
              <p>Ange ditt nya lösenord</p>
            </div>

            <div class="mb-3">
              <input
                v-model="resetPasswordForm.password"
                type="password"
                class="form-control shadow"
                placeholder="Nytt lösenord"
                required
              />
            </div>
            <div class="mb-3">
              <input
                v-model="resetPasswordForm.confirmPassword"
                type="password"
                class="form-control shadow"
                placeholder="Bekräfta lösenord"
                required
              />
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-11">
            <div class="d-grid">
              <button
                type="submit"
                class="btn btn-primary shadow"
                :disabled="submitting"
              >
                Återställ
              </button>
            </div>
          </div>
        </div>
        <div class="mt-4 text-center">
          <router-link v-bind:to="'/login/'"> Tillbaka </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive, getCurrentInstance, ref } from "vue";
import { useRoute } from "vue-router";
import Members from "../api/members";
import router from "../router";
import LoadingModal from "../components/LoadingModal";

export default {
  components: { LoadingModal },
  setup() {
    const { proxy } = getCurrentInstance();
    const $toast = proxy.$toast;
    const submitting = ref(false);
    const token = useRoute().params.token;

    const form = reactive({
      email: "",
    });

    const resetPasswordForm = reactive({
      password: "",
      confirmPassword: "",
    });

    function requestResetPassword() {
      submitting.value = true;
      Members.requestResetPassword(form.email)
        .then(() => {
          $toast.success("Instruktioner har skickats till din e-postadress");
          submitting.value = false;
          router.push("/login");
        })
        .catch(() => {
          $toast.error("Användaren hittades inte");
          submitting.value = false;
        });
    }

    function resetPassword() {
        if(resetPasswordForm.password != resetPasswordForm.confirmPassword) {
            $toast.error("Lösenorden matchar inte");
            return;
        }

      submitting.value = true;
      Members.resetPassword(resetPasswordForm.password, token)
        .then(() => {
          $toast.success("Ditt lösenord är nu återställt");
          submitting.value = false;
          router.push("/login");
        })
        .catch((error) => {
          $toast.error(error.response.data);
          submitting.value = false;
        });
    }


    return { form, resetPassword, requestResetPassword, submitting, token, resetPasswordForm };
  },
};
</script>

<style>
</style>