<template>
  <div class="row justify-content-center">
    <div class="col-10">
      <div class="row justify-content-center">
        <div class="col-9">
          <div class="text-center my-5">
            <img src="../assets/images/logo.svg" alt="Logo" />
          </div>
        </div>
      </div>

      <form @submit.prevent="login">
        <div class="mt-3 mb-4">
          <div class="mb-3">
            <input
              v-model="form.email"
              type="text"
              class="form-control shadow"
              placeholder="E-postadress"
              required
            />
          </div>
          <div class="mb-3">
            <input
              v-model="form.password"
              type="password"
              class="form-control shadow"
              placeholder="Lösenord"
              required
            />
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-11">
            <div class="d-grid">
              <button type="submit" class="btn btn-primary shadow">
                Logga in
              </button>
            </div>

            <div class="mt-4 text-center">
              <router-link v-bind:to="'/reset-password/'">
                Glömt lösenord
              </router-link>
            </div>
            <hr class="mt-4 mb-4" />
            <div class="mt-4 text-center">
              <p class="mb-1">
                <small>
                  <a href="https://www.netbiter.net/" target="_blank">Klicka här</a> för att komma till gamla
                  webbappen</small
                >
              </p>
              <p>
                <small
                  ><a
                    href="https://www.ostorpsbevattning.se/smartnet/"
                    target="_blank"
                    >Klicka här</a
                  >
                  för mer information om SMART</small
                >
              </p>

              <p class="mt-4">
                <small>
                  <strong>Behöver du hjälp?</strong><br />
                  <a href="mailto:support@smartnet.nu">support@smartnet.nu</a><br>                  
                  <a href="tel:0430-123 85" class="text-dark">0430-123 85</a>
                </small>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div v-if="isLoggedIn" />
  </div>
</template>

<script>
import { reactive, computed, onMounted, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import Members from "../api/members";
import router from "../router";
// import { router } from 'vue-router';

export default {
  setup() {
          let urlParams = new URLSearchParams(window.location.search);
      console.log(urlParams.has('t')); // true
      console.log(urlParams.get('t')); 
      
    const form = reactive({
      email: "",
      password: "",
    });

    const store = useStore();
    const { proxy } = getCurrentInstance();
    const $toast = proxy.$toast;

    function login() {
      Members.login(form.email, form.password)
        .then((res) => {
          localStorage.accessToken = res.data.access_token;

          getMember();
        })
        .catch(() => {
          $toast.error("Fel användarnamn eller lösenord");
        });
    }

    function getMember() {
      Members.getCurrentMember().then((res) => {
        store.commit("setIsLoggedIn", true);
        store.commit("setAuthUser", res.data);
      });
    }

    onMounted(() => {
      if (!localStorage.accessToken) {
        router.push("/login");
      } else {
        getMember();
      }
    });

    const isLoggedIn = computed(() => {
      if (store.state.isLoggedIn) {
        router.push("/projects");
      }

      return store.state.isLoggedIn;
    });

    return { login, form, isLoggedIn };
  },
};
</script>